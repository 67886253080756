<template>
    <div>
        <b-list-group>
            <b-list-group-item
                :to="{ name: 'PrintQueueList' }"
                class="flex-column align-items-start"
                v-if="$auth.check( roles.ROLE_ADMIN )"
            >
                <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">Сервис "Очередь печати"</h5>

                    <small class="danger-font">
                        <i class="fas fa-exclamation-triangle"></i>
                    </small>
                </div>

                <p class="mb-1">
                    Сервис позволяет отслеживать изменения в очередях печати
                    таких таблиц, как: front_label, back_label, nomenclature_lot_label, etc.<br/>
                    и при необходимости очищать очередь любую из таблиц.
                </p>

                <small>
                    <b>Внимание, интерфейс влияет на уже выполняющиеся задания.
                    Будьте аккуратны при очистке очередей!</b>
                </small>
            </b-list-group-item>

        </b-list-group>
    </div>
</template>

<script>
    import { ROLE_ADMIN, ROLE_STOCKMAN, ROLE_STORAGE_SENIOR, ROLE_SHIFT_SUPERVISOR } from "../../utils/Roles";

    export default {
        name: "ServiceList",

        data() {
            return {
                roles: {
                    ROLE_ADMIN: ROLE_ADMIN,
                    ROLE_STOCKMAN: ROLE_STOCKMAN,
                    ROLE_STORAGE_SENIOR: ROLE_STORAGE_SENIOR,
                    ROLE_SHIFT_SUPERVISOR: ROLE_SHIFT_SUPERVISOR
                }
            };
        }
    }
</script>

<style lang="sass" scoped>
    .danger-font
        color: #900
</style>
